import request from '@/utils/request'
/** 角色  S */
// 角色列表
export const roleList = (params) => {
  return request({
    url: '/roleList',
    method: 'GET',
    params
  })
}

// 添加角色
export const addRoleAPI = (data) => {
  return request({
    url: '/addRole',
    method: 'POST',
    data
  })
}

// 修改角色
export const roleSaveAPI = (data) => {
  return request({
    url: '/roleSave',
    method: 'POST',
    data
  })
}

// 角色所拥有的权限
export const roleOwnershipPermissionsAPI = (params) => {
  return request({
    url: '/rolemenuList',
    method: 'GET',
    params
  })
}

// 为角色修改权限
export const distributePermission = (data) => {
  return request({
    url: '/addRmenu',
    method: 'POST',
    data
  })
}
/** 角色  E */

/** 权限列表 S */
export const permissionListAPI = (params) => {
  return request({
    url: '/menuList',
    method: 'GET',
    params
  })
}

/** 权限列表 E */

/** 员工管理 S */
// 员工列表
export const userallListAPI = (params) => {
  return request({
    url: '/userallList',
    method: 'GET',
    params
  })
}

// 获取指定用户的角色
export const userSCornerListAPI = (params) => {
  return request({
    url: '/user_role',
    method: 'GET',
    params
  })
}

// 为用户分配角色
export const userAllocationRoleAPI = (data) => {
  return request({
    url: 'useraddrole',
    method: 'POST',
    data
  })
}

// 添加员工
export const addEmployeeAPI = (data) => {
  return request({
    url: 'addUser',
    method: 'POST',
    data
  })
}

/** 员工管理 E */
