<template>
  <div class="">
    <el-card>
      <el-table
        :data="allPermission"
        style="width: 100%; margin-bottom: 20px"
        row-key="menuId"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: '#f2f2f2' }"
      >
        <el-table-column prop="name" label="权限名称" width="180">
        </el-table-column>
        <el-table-column prop="url" label="权限标识"> </el-table-column>
        <el-table-column prop="permissionDesc"> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script setup>
import { permissionListAPI } from '@/api/organization'
import { ref } from 'vue'

/** 表格 S */
const allPermission = ref([])
const getPermissionList = async () => {
  allPermission.value = await permissionListAPI()
}
getPermissionList()
</script>

<style lang="scss" scoped></style>
